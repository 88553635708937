<template>
  <div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="查看发货通知单" custom-class="custom-dialog-max-width" @closed="form = null">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-suffix=":" label-position="right">
        <el-form ref="form" :model="form" label-width="80px" label-suffix=":" label-position="right">
          <div class="h">
            <form-info-item label="发货单号">
              <div style="width: 180px;">{{form.formCode}}</div>
            </form-info-item>
            <form-info-item label="单据日期">
              <div style="width: 180px;">{{new Date(form.formTime).format('yyyy/MM/dd')}}</div>
            </form-info-item>

            <!-- <form-info-item label="发货库房">
              <div style="width: 440px;">{{form.warehouseName}}</div>
            </form-info-item> -->
            <form-info-item label="创建时间(人)" label-width="90px">
              <div style="width: 220px;">{{new Date(form.createAt).format('yyyy/MM/dd hh:mm:ss') +' ('+form.createBy+')'}}</div>
            </form-info-item>
          </div>
          <div class="h">
            <form-info-item label="收货人">
              <div style="width: 180px;">{{form.customerName}}</div>
            </form-info-item>
            <form-info-item label="联系电话">
              <div style="width: 180px;">{{form.customerPhone}}</div>
            </form-info-item>
            <form-info-item label="收货地址">
              <div style="width: 440px;">{{form.provinceName}}{{form.cityName}}{{form.districtName}}{{form.customerAddress}}</div>
            </form-info-item>
            <form-info-item label="审批时间(人)" label-width="90px" v-if="form.status!=='submit' && form.auditAt">
              <div style="width: 220px;">{{new Date(form.auditAt).format('yyyy/MM/dd hh:mm:ss')+' ('+form.auditBy+')'}}</div>
            </form-info-item>
          </div>
          <div class="h">
            <form-info-item label="零售单号">
              <div style="width: 180px;">{{form.dependFormCode}}</div>
            </form-info-item>
            <form-info-item label="合同号">
              <div style="width: 180px;">{{form.saleForm.contractNo}}</div>
            </form-info-item>
            <form-info-item label="门店">
              <div style="width: 440px;">{{form.saleForm.shopName}}</div>
            </form-info-item>
          </div>
          <form-info-item label="发货明细">
            <el-table border :data="form.items" highlight-current-row empty-text="没有商品信息">
              <el-table-column prop="code" label="商品编码" width="120" />
              <el-table-column prop="erpCode" label="ERP编码" width="120" />
              <el-table-column prop="goodsName" label="商品名称" min-width="180" />
              <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" show-overflow-tooltip/>
              <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
              <el-table-column prop="warehouseName" label="发货库房" width="90" />
              <el-table-column label="当前库房库存" width="100" align="right">
                <template slot-scope="scope">
                  <span v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</span>
                  <div v-else-if="scope.row._stockInfo.error" class="h c r">
                    <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <span v-else>{{scope.row._stockInfo.total}}</span>
                </template>
              </el-table-column>
              <el-table-column label="发货数量" align="right" width="120" prop="quantity"></el-table-column>
              <el-table-column label="已出库数量" align="right" width="120" prop="outboundQuantity"></el-table-column>
              <el-table-column label="发货日期" width="155" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-model.trim="scope.row.deliveryDate" :clearable="false" size="mini" type="date" value-format="timestamp" placeholder="发货日期" style="width: 100%;" v-if="form.status === 'submit'" />
                  <span v-else>{{scope.row.deliveryDate?new Date(scope.row.deliveryDate).format('yyyy/MM/dd'):''}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="info" label="备注" width="100" show-overflow-tooltip />
            </el-table>
          </form-info-item>
          <form-info-item label="备注">{{form.info}}</form-info-item>
        </el-form>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { get } from "@/api/deliveryNotice";
import { getGoodsById } from "@/api/warehouse";
export default {
  data() {
    return {
      dialog: false,
      form: null,
    };
  },
  methods: {
    resetForm(form) {
      this.dialog = true;
      if (form && form.formId) {
        get(form.formId).then((res) => {
          if (res.items && res.items.length) {
            res.items.forEach((o) => {
              o._maxQuantity = (o.quantity || 0) + (o.maxQuantity || 0);
              o._stockInfo = {
                loading: false,
                error: false,
                total: 0,
              };
            });
          }
          this.form = res;
          this.loadStockInfo();
        });
      }
    },
    loadStockInfo() {    
        (this.form.items || []).forEach((g) => {
          if(g.warehouseId){
            g._stockInfo.loading = true;
            g._stockInfo.error = false;
            getGoodsById(g.goodsId, g.warehouseId)
              .then((res) => {
                let count = 0;
                (res || []).forEach((ro) => {
                  count += ro.count;
                });
                g._stockInfo.total = count;
              })
              .catch((err) => {
                g._stockInfo.error = err.message || "获取库存信息失败";
              })
              .finally((_) => {
                g._stockInfo.loading = false;
              });
          }
        });
    },
  },
};
</script>